import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Romatem Online Randevu",
        layout: "full",
      },
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Romatem Online Randevu",
        layout: "full",
      },
    },
    {
      path: "/kullanici",
      name: "Kullanıcı",
      component: () => import("@/views/Kullanici.vue"),
      meta: {
        pageTitle: "Romatem Online Randevu",
        layout: "full",
      },
    },
    {
      path: "/randevuiptal",
      name: "randevuiptal",
      component: () => import("@/views/randevuIptal.vue"),
      meta: {
        pageTitle: "Romatem Online Randevu",
        layout: "full",
      },
    },
    {
      path: "*", // Tüm tanımlı olmayan rotalar için
      redirect: "/", // Ana sayfaya yönlendir
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
